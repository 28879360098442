import { Injectable, inject } from '@angular/core';

import { BooktechAppService } from '@btlib-core';

export const APP_DATAID = {

}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  name = "AppService"

  

  public bas = inject(BooktechAppService);

  constructor() {
    if(this.bas.envtest) console.log("AppService: ", this.bas.settings?.appId);
    
   }

}
